<template>
  <div id="contentUs">
    <t-header :nav-light="false"></t-header>
    <section
      class="bg-half bg-light d-table w-100"
      style="background: url('media/images/images/contact-detail.jpg') center center;"
    >
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Kullanım Koşulları</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <a @click="$router.push({ name: 'user.index' })">{{
                        $t("index.home")
                      }}</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Kullanım Koşulları
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- questions section begin -->
    <div class="questions-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
            <h1 class="turuncu-baslik">
              Napolyon.com Web Sitesi Kullanım Koşulları
            </h1>
            <p>
              <b>
                1. KULLANIM KOŞULLARI
              </b>
              <br />
              <b>
                <br />
                1.1.
              </b>
              Bu web sitesini (Kısaca "SİTE" olarak anılacaktır) kullanmak için
              lütfen aşağıda yazılı koşulları okuyunuz. Bu koşulları kabul
              etmediğiniz taktirde "SİTE"yi kullanmaktan vazgeçiniz. "SİTE"
              sahibi bu "SİTE"de yer alan veya alacak olan bilgileri, formları,
              içeriği, "SİTE"'yi, "SİTE" kullanma koşullarını dilediği zaman
              değiştirme hakkını saklı tutmaktadır.<br />
              <b>
                <br />
                1.2.
              </b>
              Bu "SİTE"'nin sahibi Dor Insight, Saray Mh. Dr. Adnan Büyükdeniz
              Cad. No:4/2 Cessas Plaza Kat:3 Ümraniye adresinde ikamet eden
              DORINSIGHT ARAŞTIRMA VE DANIŞMANLIK HİZMETLERİ TİCARET ANONİM
              ŞİRKETİ bundan böyle kısaca "Napolyon.com" olarak
              anılacaktır)'dir. "SİTE"'da sunulan hizmetler DORINSIGHT ARAŞTIRMA
              VE DANIŞMANLIK HİZMETLERİ TİCARET ANONİM ŞİRKETİ tarafından
              sağlanmaktadır. <br />
              <b>
                <br />
                1.3.
              </b>
              Bu "SİTE"'de sunulan hizmetlerden belirli bir bedel ödeyerek yada
              bedelsiz olarak yararlananlar veya herhangi bir şekilde "SİTE"ye
              erişim sağlayan her gerçek ve tüzel kişi aşağıdaki kullanım
              koşullarını kabul etmiş sayılmaktadır. İşbu sözleşme içinde
              belirtilen koşulları "Napolyon.com" dilediği zaman değiştirebilir.
              Bu değişiklikler periyodik olarak "SİTE"'da yayınlanacak ve
              yayınlandığı tarihte geçerli olacaktır. "Napolyon.com" tarafından
              işbu sözleşme hükümlerinde yapılan her değişikliği "SİTE"
              hizmetlerinden yararlanan ve "SİTE"ye erişim sağlayan her gerçek
              ve tüzel kişi önceden kabul etmiş sayılmaktadır. <br />
              <b>
                <br />
                1.4.
              </b>
              İşbu "Site Kullanım Koşulları" 10/02/2010 tarihinde en son
              değişiklik yapılarak (Versiyon 2.0.) ve web sitesi üzerinden
              yayınlanarak; "SİTE"yi kullanan her kişi tarafından erişimi mümkün
              kılınıp yürürlülüğe konmuştur. İşbu "Site Kullanım Koşulları"
              ayrıca, "Napolyon.com" hizmetlerinden yararlanacak olan
              kullanıcılarla ve iş ortaklarıyla yapılmış ve/veya yapılacak olan
              her türlü sözleşmenin de ayrılmaz bir parçasıdır. <br />
              <b>
                <br />
                2. TANIMLAR
              </b>
              <br />
              <b>
                <br />
                2.1.
              </b>
              "SİTE": "Napolyon.com" tarafından belirlenen çerçeve içersinde
              çeşitli hizmetlerin ve içeriklerin sunulduğu çevrimiçi (on-line)
              ortamdan erişimi mümkün olan web sitesi. <br />
              <b>
                <br />
                2.2.
              </b>
              KULLANICI: "Napolyon.com" "SİTE"ne çevrimiçi (on-line) ortamdan
              erişen her gerçek ve tüzel kişi. <br />
              <b>
                <br />
                2.3.
              </b>
              LİNK: "SİTE" üzerinden bir başka web sitesine, dosyalara, içeriğe
              veya başka bir web sitesinden SİTE"ye, dosyalara ve içeriğe
              erişimi mümkün kılan bağlantı. <br />
              <b>
                <br />
                2.4.
              </b>
              İÇERİK: "Napolyon.com" "SİTE"si ve/veya herhangi bir web
              sitesinden yayınlanan veya erişimi mümkün olan her türlü bilgi,
              dosya, resim, rakam, fiyat v.b görsel, yazınsal ve işitsel
              imgeler. <br />
              <b>
                <br />
                3. GENEL HÜKÜMLER
              </b>
              <br />
              <b>
                <br />
                3.1. </b
              >"Napolyon.com", "SİTE" dahilinde erişime açtığı hizmetler ve
              içeriklerden hangisinin ücrete tabi olacağını belirlemekte tamamen
              serbesttir. <br />
              <b>
                <br />
                3.2. </b
              >"Napolyon.com"'un sunduğu hizmetlerden yararlananlar ve siteyi
              kullananlar, yalnızca hukuka uygun ve şahsi amaçlarla "SİTE"
              üzerinde işlem yapabilirler. Kullanıcıların, "SİTE" dahilinde
              yaptığı her işlem ve eylemdeki hukuki ve cezai sorumluluk
              kendilerine aittir. Her kullanıcı, "Napolyon.com" ve/veya başka
              bir üçüncü şahsın haklarına tecavüz teşkil edecek nitelikteki
              herhangi bir iş ve eylemde bulunmayacağını; yazılı, görsel ve
              işitsel bilgileri açıklamayacağını, "Napolyon.com"a açıkladığı her
              türlü yazılı, görsel ve işitsel bilginin "Napolyon.com"a
              açıkladığı sırada her türlü biçimde kullanılması, işlenmesi,
              saklanması, açıklanması ve üçüncü kişilere karşı ifşa edilmesi
              konusunda münhasır hak sahibi olduğunu kabul, beyan ve taahhüt
              eder. "KULLANICI" "SİTE" dahilinde bulunan resimleri, metinleri,
              görsel ve işitsel imgeleri, video klipleri, dosyaları,
              veritabanları, katalogları ve listeleri çoğaltmayacağı,
              kopyalamayacağı, dağıtmayacağı, işlemeyeceğini, gerek bu eylemleri
              ile gerekse de başka yollarla "Napolyon.com" ile doğrudan ve/veya
              dolaylı olarak rekabete girmeyeceğini kabul ve taahhüt etmektedir.
              <br />
              <b>
                <br />
                3.3. </b
              >"SİTE" dahilinde üçüncü kişiler tarafından sağlanan hizmetlerden
              ve yayınlanan içeriklerden dolayı "Napolyon.com'un, işbirliği
              içinde bulunduğu kurumların, "Napolyon.com" çalışanlarının ve
              yöneticilerinin, "Napolyon.com" yetkili satıcılarının sorumluluğu
              bulunmamaktadır. Herhangi bir üçüncü kişi tarafından sağlanan ve
              yayınlanan bilgilerin, içeriklerin, görsel ve işitsel imgelerin
              doğruluğu ve hukuka uygunluğunun taahhüdü bütünüyle bu eylemleri
              gerçekleştiren üçüncü kişilerin sorumluluğundadır. "Napolyon.com",
              üçüncü kişiler tarafından sağlanan hizmetlerin ve içeriklerin
              güvenliğini, doğruluğunu ve hukuka uygunluğunu taahhüt ve garanti
              etmemektedir. <br />
              <b>
                <br />
                3.4. </b
              >"KULLANICI"lar, "SİTE"yi kullanarak, "Napolyon.com"un, diğer
              "KULLANICI"ların ve üçüncü kişilerin aleyhine hiçbir faaliyette
              bulunmayacaklardır. "KULLANICI"ların işbu "Site Kullanım
              Koşulları" hükümlerine ve hukuka aykırı olarak gerçekleştirdikleri
              "SİTE" üzerindeki faaliyetler nedeniyle üçüncü kişilerin
              uğradıkları veya uğrayabilecekleri zararlardan dolayı
              "Napolyon.com"'un doğrudan ve/veya dolaylı hiçbir sorumluluğu
              yoktur. <br />
              <b>
                <br />
                3.5. </b
              >"KULLANICI"lar, "Napolyon.com"a "SİTE" dahilinde kendileri
              tarafından sağlanan bilgilerin ve içeriklerin doğru ve hukuka
              uygun olduğunu kabul ve taahhüt etmektedirler. "Napolyon.com",
              "KULLANICI"lar tarafından "Napolyon.com"a iletilen veya "SİTE"
              üzerinden kendileri tarafından yüklenen, değiştirilen ve sağlanan
              bilgilerin ve içeriklerin doğruluğunu araştırma; bu bilgi ve
              içeriklerin güvenli, doğru ve hukuka uygun olduğunu taahhüt ve
              garanti etmekle yükümlü ve sorumlu değildir. <br />
              <b>
                <br />
                3.6. </b
              >"KULLANICI"lar, "SİTE" dahilinde Türk Ticaret Kanunu hükümleri
              uyarınca haksız rekabete yol açacak faaliyetlerde bulunmayacağını,
              "Napolyon.com"'in ve üçüncü kişilerin şahsi ve ticari itibarı
              sarsacak, kişilik haklarına tecavüz ve taarruz edecek fiilleri
              gerçekleştirmeyeceğini kabul ve taahhüt etmektedir.
              <br />
              <b>
                <br />
                3.7. </b
              >"Napolyon.com", "SİTE" dahilinde sunulan hizmetleri ve içerikleri
              her zaman değiştirebilme hakkını saklı tutmaktadır.
              "Napolyon.com", bu hakkını hiçbir bildirimde bulunmadan ve önel
              vermeden kullanabilir. "KULLANICI"lar, "Napolyon.com"'un talep
              ettiği değişiklik ve/veya düzeltmeleri ivedi olarak yerine
              getirmek zorundadırlar. "Napolyon.com" tarafından talep edilen
              değişiklik ve/veya düzeltme istekleri gerekli görüldüğü takdirde
              "Napolyon.com" tarafından yapabilir. "Napolyon.com" tarafından
              talep edilen değişiklik ve/veya düzeltme taleplerinin,
              "KULLANICI"lar tarafından zamanında yerine getirilmemesi sebebiyle
              doğan veya doğabilecek zararlar, hukuki ve cezai sorumluluklar
              tamamen kullanıcılara aittir. <br />
              <b>
                <br />
                3.8.
              </b>
              "SİTE" üzerinden, "Napolyon.com"'un kendi kontrolünde olmayan ve
              başkaca üçüncü kişilerin sahip olduğu ve işlettiği başka web
              sitelerine ve/veya "İÇERİK"lere ve/veya dosyalara "Napolyon.com"
              tarafından link verilebilir. Bu link'ler sadece referans kolaylığı
              nedeniyle sağlanmış olup ilgili web sitesini veya işleten kişiyi
              desteklemek amacıyla veya web sitesi veya içerdiği bilgilere
              yönelik herhangi bir türde bir beyan veya garanti niteliği
              taşımamaktadır. "SİTE" üzerindeki linkler vasıtasıyla erişilen web
              siteleri, dosyalar ve içerikler, bu linkler vasıtasıyla erişilen
              web sitelerinden sunulan hizmetler veya ürünler veya bunların
              içeriği hakkında "Napolyon.com"'un herhangi bir sorumluluğu
              yoktur. <br />
              <b>
                <br />
                3.9.
              </b>
              "Napolyon.com", "SİTE"' üzerinden "KULLANICILAR" tarafından
              kendisine iletilen bilgileri "Gizlilik Politikası", kullanıcılarla
              ve diğer taraflarla yaptığı sözleşmelerin hükümleri doğrultusunda
              kullanabilir. Bu bilgileri işleyebilir, bir veritabanı üzerinde
              tasnif edip muhafaza edebilir. "Napolyon.com" aynı zamanda;
              kullanıcı veya ziyaret edenin kimliği, adresi, elektronik posta
              adresi, telefonu, IP adresi, "SİTE"nin hangi bölümlerini ziyaret
              ettiği, domain tipi, tarayıcı (browser) tipi, tarih ve saat gibi
              bilgileri de kullanıcılarla, iş ortaklarıyla ve taraflarla yaptığı
              sözleşmeler kapsamında ve istatistiki değerlendirme ve kişiye
              yönelik hizmetler sunma gibi amaçlarla kullanabilir. <br />
              <b>
                <br />
                4. FİKRİ MÜLKİYET HAKLARI
              </b>
              <br />
              <b>
                <br />
                4.1. </b
              >Bu "SİTE" dahilinde erişilen veya hukuka uygun olarak
              kullanıcılar tarafından sağlanan bilgiler ve bu "SİTE"nin (sınırlı
              olmamak kaydıyla tasarım, metin, imge, html kodu ve diğer kodlar)
              tüm elemanları (Hepsi birden "Napolyon.com"nun telif haklarına
              tabi çalışmaları olarak anılacaktır) "Napolyon.com"'a ait ve/veya
              "Napolyon.com" tarafından üçüncü bir kişiden lisans altında
              alınmıştır. Kullanıcılar, "Napolyon.com" hizmetlerini,
              "Napolyon.com" bilgilerini ve "Napolyon.com"'in telif haklarına
              tabi çalışmalarını yeniden satmak, işlemek, paylaşmak, dağıtmak,
              sergilemek veya başkasının "Napolyon.com"'un hizmetlerine erişmesi
              veya kullanmasına izin vermek hakkına sahip değildirler. İşbu
              "Site Kullanım Koşulları" dahilinde "Napolyon.com" tarafından
              sarahaten izin verilen durumlar haricinde "Napolyon.com"un telif
              haklarına tabi çalışmalarını çoğaltamaz, işleyemez, dağıtamaz veya
              bunlardan türemiş çalışmalar yapamaz veya hazırlayamaz.
              <br />
              <b>
                <br />
                4.2. </b
              >İşbu "Site Kullanım Koşulları" dahilinde "Napolyon.com"
              tarafından sarahaten yetki verilmediği hallerde "Napolyon.com";
              "Napolyon.com" hizmetleri, "Napolyon.com" bilgileri,
              "Napolyon.com" telif haklarına tabi çalışmaları, "Napolyon.com"
              ticari markaları, "Napolyon.com" ticari görünümü veya bu site
              vasıtasıyla sağladığı başkaca varlık ve bilgilere yönelik tüm
              haklarını saklı tutmaktadır.<br />
              <b>
                <br />
                5. SİTE KULLANIM KOŞULLARINDA DEĞİŞİKLİKLER
              </b>
              <br />
              "Napolyon.com", tamamen kendi takdirine bağlı olarak işbu "Site
              Kullanım Koşulları"nı herhangi bir zamanda "SİTE"'de ilan ederek
              değiştirebilir. İşbu "Site Kullanım Koşulları"nın değişen
              hükümleri, ilan edildikleri tarihte geçerlilik kazanacaktır. İşbu
              "Site Kullanım Koşulları" kullanıcının tek taraflı beyanları ile
              değiştirilemez. <br />
              <b>
                <br />
                6. MUCBİR SEBEPLER
              </b>
              <br />
              Hukuken mücbir sebep sayılan tüm durumlarda, "Napolyon.com" işbu
              "Site Kullanım Koşulları" ve gizlilik politikasından herhangi
              birini geç ifa etme veya ifa etmeme nedeniyle yükümlü değildir. Bu
              ve bunun gibi durumlar, "Napolyon.com" açısından, gecikme veya ifa
              etmeme veya temerrüt addedilmeyecek veya bu durumlar için
              "Napolyon.com"un herhangi bir tazminat yükümlülüğü doğmayacaktır.
              "Mücbir sebep" terimi, ilgili tarafın makul kontrolü haricinde ve
              "Napolyon.com"un gerekli özeni göstermesine rağmen önleyemediği
              olaylar olarak yorumlanacaktır. Bunu yanında sınırlı olmamak
              koşuluyla, doğal afet, isyan, savaş, grev, iletişim sorunları,
              altyapı ve internet arızaları, elektrik kesintisi ve kötü hava
              koşulları gibi durumlar mücbir sebep olaylarına dahildir.<br />
              <b>
                <br />
                7. UYGULANACAK HUKUK VE YETKİ
              </b>
              <br />
              İşbu "Site Kullanım Koşulları" uygulanmasında, yorumlanmasında ve
              bu "Site Kullanım Koşulları" dahilinde doğan hukuki ilişkilerin
              yönetiminde yabancılık unsuru bulunması durumunda Türk kanunlar
              ihtilafı kuralları hariç olmak üzere Türk Hukuku uygulanacaktır.
              İşbu sözleşmeden dolayı doğan veya doğabilecek her türlü ihtilafın
              hallinde İstanbul Mahkemeleri ve İcra Daireleri yetkilidir. <br />
              <b>
                <br />
                8. YÜRÜRLÜLÜK ve KABUL
              </b>
              <br />
              İşbu "Site Kullanım Koşulları" "Napolyon.com" tarafından "SİTE"
              içersinde ilan edildiği tarihte yürürlülük kazanır. Kullanıcılar,
              işbu sözleşme hükümlerini "SİTE"yi kullanmakla kabul etmiş
              olmaktadırlar. "Napolyon.com", dilediği zaman iş bu sözleşme
              hükümlerinde değişikliğe gidebilir ve değişiklikler versiyon
              numarası ve değişiklik tarihi belirtilerek "SİTE" üzerinde
              yayınlandığı tarihte yürürlülüğe girer.
            </p>
            <br />
            <hr class="bordo2" />
            <p align="justify"></p>
            <h1 class="turuncu-baslik">
              Napolyon.com Website Conditions of Use
            </h1>
            <b>
              <br />
              1. CONDITIONS OF USE </b
            ><br />
            <br /><b>1.1.</b> Please read the below written conditions in order
            to use this website (hereinafter will be referred to as the "SITE").
            In case you do not accept these conditions, please stop using the
            SITE. The right of the SITE owner to amend at any time the
            information, forms and content that takes place or will take place
            on the SITE, the SITE and the conditions of use of the SITE is
            reserved.<br />
            <br /><b>1.2.</b> The owner of the SITE is DORINSIGHT ARAŞTIRMA VE
            DANIŞMANLIK HİZMETLERİ TİCARET ANONİM ŞİRKETİ resident in Saray Mh.
            Dr. Adnan Büyükdeniz Cad. No:4/2 Cessas Plaza Kat:3 Ümraniye. The
            services on the SITE are provided by DORINSIGHT ARAŞTIRMA VE
            DANIŞMANLIK HİZMETLERİ TİCARET ANONİM ŞİRKETİ (hereinafter will be
            referred to as "Napolyon.com"). <br />
            <br /><b>1.3.</b> All the natural and legal persons utilizing,
            whether charged or for free, from the services offered on the SITE
            and/or accessing the SITE in any way, are deemed to have accepted
            the below conditions of use. Napolyon.com can amend the conditions
            designated in this "Napolyon.com Website Conditions of Use" at any
            time. Such amendments shall be published on the SITE periodically
            and shall be effective on the date of that publication. All the
            natural and legal persons utilizing from the services on the SITE
            and/or accessing to the SITE are deemed to have accepted beforehand
            all the amendments made by "Napolyon.com" on the provisions of this
            "Napolyon.com Website Conditions of Use". <br />
            <br /><b>1.4. </b>This "Napolyon.com Website Conditions of Use" have
            been lastly amended (Version 2.0) and published on the SITE on
            www.napolyon.com and accordingly has been made available and
            effective for access by every user of the SITE. This "Napolyon.com
            Website Conditions of Use" is also an integral part and parcel of
            all kinds of agreements made or to be made with the users to utilize
            from Napolyon.com services and their business partners.<br />
            <br /><b>2. DEFINITIONS</b> <br />
            <br /><b>2.1.</b> SITE:The web site on which several services and
            content designated by Napolyon.com are provided and to which on line
            access is possible. <br />
            <br /><b>2.2.</b> USER(S): Natural or legal person(s) who access(es)
            on line to the SİTE. <br />
            <br /><b>2.3.</b> LINK(S)<b>:</b> Link(s) which enable(s) access via
            the SİTE to another web site, files, content or from another web
            site to the SITE.<br />
            <br /><b>2.4.</b> CONTENT(S):Every visual, literary, audio image
            such as information, file, picture, number, price etc which is
            published from or to which access is possible from the SITE and/or
            any other web site. <br />
            <br /><b>3. GENERAL PROVISIONS</b> <br />
            <br /><b>3.1. </b>Napolyon.com is completely free to decide the
            price for the services and CONTENT which it enables access in the
            SITE. <br />
            <br /><b>3.2. </b>The persons who utilize from the services provided
            by Napolyon.com and the USERS of the SITE can only make transactions
            on the SITE for legal and personal purposes. All the legal and
            criminal responsibility of the USERS for the activities and
            transactions they do within the SITE lies on the USERS. Every USER
            accepts, declares and undertakes that he/she will not do any
            activities or transactions or make public any written, visual, audio
            information of an infringing nature to the rights of Napolyon.com
            and/or a third party and that he/she is the sole right owner at the
            moment of disclosure to Napolyon.com to use, process, store, express
            and disclose to third parties, in any way, every written, visual and
            audio information that he/she expressed to Napolyon.com. The USER
            accepts and undertakes not to reproduce, copy, distribute, process
            the pictures, texts, visual or audio images, videos, files,
            databases, catalogues and lists within the SITE or by such actions
            or by other means compete directly and/or indirectly with
            Napolyon.com. <br />
            <br /><b>3.3. </b>Napolyon.com, its affiliates, employees,
            administrators and authorized resellers shall have no responsibility
            for the services provided and the CONTENT issued within the SITE by
            the third parties. Undertaking of the accuracy and legality of the
            information, CONTENT, visual and audio images provided and issued by
            any third party is under the sole responsibility of that third
            party. Napolyon.com does not undertake and guarantee the security,
            accuracy and legality of the services and CONTENT provided by third
            parties.<br />
            <b>3.4. </b>USERS shall by using the SITE conduct no activity
            against Napolyon.com, the other USERS or any third parties.
            Napolyon.com shall have no direct and/or indirect responsibility for
            the damages that any third parties suffer or might suffer resulting
            from the activities that the USERS make which are against the law
            and the provisions of this "Napolyon.com Website Conditions of Use"
            <br />
            <br /><b>3.5. </b>USERS accept and undertake that the information
            and CONTENT that they have provided to Napolyon.com within the SITE
            are accurate and lawful. Napolyon.com shall have no liability or
            responsibility to investigate the accuracy of the information and
            CONTENT transmitted to Napolyon.com or uploaded, modified or
            provided on the SITE, Napolyon.com shall have no liability or
            responsibility to undertake and guarantee the security, accuracy and
            legality of such information and CONTENT. &nbsp;<br />
            <br /><b>3.6. </b>USERS accept and undertake not to conduct any
            activities within the SITE that would result in unfair competition
            in accordance with the provisions of the Turkish Trade Law and not
            to perform any acts that would infringe or offense the personal
            rights or would harm the personal and commercial reputation of
            Napolyon.com and any third parties.<br />
            <br /><b>3.7. </b>Napolyon.com reserves the right to change at any
            time, the services and CONTENT provided within the SITE.
            Napolyon.com can use such right without making any notice or giving
            any time period. USERS are obliged to urgently make the changes
            and/or corrections requested by Napolyon.com. Such changes and/or
            corrections requested by Napolyon.com can be made by Napolyon.com,
            if deemed necessary. The legal and criminal responsibilities and the
            damages that occur or might occur because the USER does not make the
            changes and/or corrections requested by Napolyon.com are solely the
            USER's.<br />
            <br /><b>3.8. </b>Napolyon.com may provide LINKS on the SITE to web
            sites and/or CONTENTS and/or files that are not under the control of
            Napolyon.com and that belong to third parties. Such LINKS are
            provided for reference only, they do not have the purpose of
            supporting the related web site or its operator and they do not have
            the quality of a declaration or a guarantee in any kind of the web
            site and the information it includes. Napolyon.com shall have no
            responsibility concerning the websites, files and CONTENT accessed
            via the LINKS on the SITE, the services or products provided from
            the web sites accessed via such LINKS or the CONTENT of such
            services or products. <br />
            <br /><b>3.9.</b> Napolyon.com can make use of the information
            transmitted by the USERS to it from the SITE in accordance with the
            "Privacy Policy" and the provisions of the agreements it has made
            with the USERS and the other parties. Napolyon.com can process,
            classify and maintain on a database such information. Additionally
            Napolyon.com can use the information regarding the USER or the
            visitor such as his/ her identity, address, electronic mail address,
            telephone number, IP address, which parts of the SITE he/she has
            visited, domain type, browser type, date, hour etc. in the context
            of the agreements Napolyon.com has made with the USERS, business
            associates and parties and for the purposes of statistical
            evaluations or providing private services.
            <br />
            <br /><b>4. INTELLECTUAL PROPERTY RIGHTS </b><br />
            <br /><b>4.1. </b>The information which can be accessed within this
            SITE and which have been legally provided by the USERS and all the
            elements (including but not limited to design, text, image, html
            code and other codes) of this SITE ("Napolyon.com's Copyrighted
            Works") are in the property of and/or used under a license from a
            third party by Napolyon.com. USERS do not have the right to resell,
            share, distribute, display Napolyon.com services, Napolyon.com
            information and Napolyon.com's Copyrighted Works and they cannot
            allow others to access or use the services. Except for the
            situations in which Napolyon.com gives explicit permission in the
            context of this "Napolyon.com Website Conditions of Use", USERS
            cannot reproduce, process, distribute or cannot make or prepare
            adaptations of Napolyon.com's Copyrighted Works. <br />
            <br /><b>4.2. </b>&nbsp;Except for the situations in which
            Napolyon.com gives explicit permission in the context of this
            "Napolyon.com Website Conditions of Use", all the rights of
            Napolyon.com in accordance with Napolyon.com, the Napolyon.com
            services, Napolyon.com information, Napolyon.com's Copyrighted
            Works, Napolyon.com trademarks, Napolyon.com trade name and all
            other assets and information it provides via the SITE are reserved.
            <br />
            <br /><b>5. AMENDMENTS TO THE CONDITIONS OF USE OF THE SITE</b>
            <br />
            Napolyon.com can amend this "Napolyon.com Website Conditions of Use"
            unilaterally and in full discretion in any time by making an
            announcement on the SITE. The amended provisions of this
            "Napolyon.com <u>Website </u>Conditions of Use" shall become valid
            on the date of their announcement. This "Napolyon.com Website
            Conditions of Use" may not be amended by the unilateral declarations
            of the USER.<br />
            <b> <br /> </b><b>6. FORCE MAJEURE</b> <br />
            In cases which are legally deemed as force majeure; Napolyon.com
            shall not have any obligations for not fulfilling or for fulfilling
            in an imperfect or in a delayed way this "Napolyon.com Website
            Conditions of Use" or the privacy policy. These and similar cases
            shall not be deemed as delay, imperfect fulfillment, unfulfillment
            or default and no indemnification obligation shall occur for
            Napolyon.com for these kind of cases. Force majeure shall be
            interpreted as the inevitable cases which happen outside the
            reasonable control of the concerned party and which Napolyon.com
            could not avoid although it has shown the necessary attention.
            Happenings including but not limited to natural disasters, riots,
            war, strikes, communication problem, infrastructure and internet
            failures, electrical power cuts or bad weather shall be deemed as
            force majeure.<br />
            <b> <br /> </b><b>7. APPLICABLE LAW AND JURISDICTION</b><br />
            In the application and interpretation of this "Napolyon.com Website
            Conditions of Use" and in the management of legal relations in the
            context of this "Napolyon.com Website Conditions of Use" where there
            is an element of foreignness Turkish Law shall be applied except for
            the Turkish rules of conflict of laws. Istanbul Central Courts and
            executive offices shall be the competent jurisdictions for any
            conflicts that occur or might occur in the context of this
            "Napolyon.com Website Conditions of Use". <br />
            <b> <br /> </b><b>8. EFFECT AND ACCEPTANCE</b><br />
            This "Napolyon.com Website Conditions of Use" shall become effective
            on the date that it is announced in the SITE by Napolyon.com. USERS
            shall be deemed to have accepted the provisions of this
            "Napolyon.com Website Conditions of Use" by using the SITE.
            Napolyon.com may amend the provisions of this "Napolyon.com Website
            Conditions of Use" at any time. Such amendments shall become
            effective on the date that they are published on the SITE by
            designating their version number and amendment date.
            <p></p>
          </div>
        </div>
      </div>
    </div>
    <!-- questions section end -->

    <!-- Start Contact -->
    <t-up-footer></t-up-footer>
    <t-footer></t-footer></div
></template>
<script>
import Footer from "@/view/layouts/Footer";
import upFooter from "@/view/layouts/upFooter";
import Header from "@/view/layouts/Header";
import feather from "feather-icons";
export default {
  name: "Terms",
  components: {
    "t-footer": Footer,
    "t-up-footer": upFooter,
    "t-header": Header
  },
  mounted() {
    window.scrollTo(0, 0);
    feather.replace();
  }
};
</script>

<style scoped></style>
